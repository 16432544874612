(function ($, viewport) {
    'use strice';
    $(document).ready(function () {
        $(".menubar").each(function () {
            //console.log('Creating new menu');
            var menu = new menuBar($(this));
            menu.init();

            menu.update({ updateWidths: true });

            $(window).load(function () {
                menu.update({ updateWidths: true });
                setTimeout(function() {
                    menu.update({ updateWidths: true });
                },100);
            });
        });
    });

    function menuBar(element) {

        var self = this;

        self.currentViewport = '';

        self.element = element;

        self.update = update;
        self.init = init;
        self.toggleMenu = toggleMenu;
        self.shouldUpdateWidths = true;

        self.totalWidth = 0;

        self.showMenu = false;

        return self;

        function toggleMenu() {
            self.showMenu = !self.showMenu;
            if (self.showMenu) {
                self.dropdownmenu.show();
                self.menuBar.addClass("menu-open");
                self.callcol.removeClass("visible-xs").hide();
                self.menucol.removeClass("col-xs-6").addClass('showMenu');
            } else {
                self.dropdownmenu.hide();
                self.menuBar.removeClass("menu-open");
                self.callcol.show().addClass("visible-xs");
                self.menucol.addClass("col-xs-6").removeClass('showMenu');
            }
        }

        function update(opts) {

            var options = $.extend({}, {
                updateWidths: false
            }, opts);

            self.menuBar.css('opactiy', 0);

            if (self.currentViewport !== viewport.current()) {
                if (viewport.is('xs')) {
                    self.bar.hide();
                    self.barxs.show();
                    self.menuitemsMore.removeClass('hidden');
                } else {
                    self.bar.show();
                    self.barxs.hide();
                    options.updateWidths = true; // as the viewport has changed, the fonts may have too
                }

                self.currentViewport = viewport.current();
            }
            if (viewport.is('>=sm')) {
                // recalculate if we need to hide or show menu items

                if (options.updateWidths || self.shouldUpdateWidths) {
                    
                    self.shouldUpdateWidths = false;

                    // first hide more button

                    self.more.hide();

                    self.menuitems.removeClass('hidden');

                    var totalWidth = 0;

                    self.menuitems.each(function (i, el) {
                        var jEl = $(el);

                        var itemWidth = jEl.width() + 1; // sometimes the result is rounded, we need the worst case width

                        jEl.data('menuitemwidth', itemWidth);
                        // console.log('data', jEl.data('menuitemwidth'));
                        totalWidth += itemWidth;
                    });

                    self.totalWidth = totalWidth;

                    
                }

                var widthAvailable = self.bar.width();

                self.more.css('min-width',0);

                var moreButtonWidth = self.more.width();

                var fitItemsTo = self.totalWidth;

                var spaceLeft = self.totalWidth;

                if (widthAvailable < self.totalWidth) {
                    self.more.show().removeClass("hidden-by-default");
                    fitItemsTo -= moreButtonWidth;
                    spaceLeft += moreButtonWidth;
                } else {
                    self.more.hide();

                }
                
                for (var right = self.menuitems.length - 1; right >= 0; right--) {
                
                    var item = $(self.menuitems[right]);
                    var itemMore = $(self.menuitemsMore[right]);
                    //console.log(spaceLeft < self.totalWidth, spaceLeft, self.totalWidth, widthAvailable, self.more.width(), item.data('menuitemwidth'), parseInt(item.data('menuitemwidth'), 10));
                    if (spaceLeft > widthAvailable) {
                        item.addClass('hidden');
                        itemMore.removeClass('hidden');
                        spaceLeft -= parseInt(item.data('menuitemwidth'), 10);
                    } else {
                        item.removeClass('hidden');
                        itemMore.addClass('hidden');
                    }
                }

                self.more.css('min-width',widthAvailable - spaceLeft);

                self.menuBar.css('opactiy', 1);

                //
            }

        }

        function init() {
            self.menuBar = $('.nav-main', self.element);
            self.menuBar.css('opactiy', 0.0);

            self.bar = $('.menu-container', self.element);
            self.barxs = $('.menu-xs-container', self.element);

            self.menuToggle = $('.menu-toggle', self.element);

            self.more = $('.menu-more-dropdown', self.element);
            self.moreButton = $('a', self.more);
            self.dropdownmenu = $('<div class="menu-dropoutmenu"></div>');
            self.dropdownRow = $('<div class="row"></div>');
            self.dropdownmenu.append(self.dropdownRow);


            self.callcol = $('.nav-main-call-col', self.element);
            self.menucol = $('.nav-main-items', self.element);
            
            self.moremenulist = $('<ul></ul>');
            self.dropdownRow.append(self.moremenulist);

            self.menuitems = $(".menu-items li", self.element).not('.menu-more-dropdown');

            self.menuitems.each(function (i, el) {
                $(el).addClass('menu-position' + i);
            });

            self.menuitemsMore = self.menuitems.clone();

            self.moremenulist.append(self.menuitemsMore);


            // set initial state
            self.dropdownmenu.hide();

            self.bar.hide();

            // add to dom

            self.dropdownmenu.appendTo($('.nav-main', self.element));


            // set event handlers



            self.menuToggle.on('click', function (e) {
                self.toggleMenu();
                e.preventDefault();
            });

            self.moreButton.on('click', function (e) {
                self.toggleMenu();
                e.preventDefault();
            });

            $(window).resize(
                viewport.changed(function () {
                    self.update();
                }, 150)
            );
        }
    }
})(jQuery, ResponsiveBootstrapToolkit);